<template>
  <el-dialog
    :title="$t('lang.gles.strategy.addTurnoverRule')"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    width="30%"
    @closed="reset"
  >
    <m-form
      ref="myForm"
      :form-data="containerTypeFilterFormData"
      label-position="right"
      :label-width="120"
      :extend-config="extendFormConfig"
    />
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{ $t('lang.gles.common.cancel') }}</el-button>
      <el-button
        type="primary"
        @click="typeFilterSubmit"
      >{{ $t('lang.gles.common.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import MForm from '@/libs_sz/components/MForm/MForm.vue'
import { getAddTurnoverRulesFormData } from '../data'
export default {
  name: 'ContainerTypeFilter',
  components: { MForm },
  props: {
  },
  data() {
    return {
      visible: false,
      submitLoading: false,
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      row: {},
      options: {}
    }
  },
  computed: {
    containerTypeFilterFormData() {
      return getAddTurnoverRulesFormData(this, this.options)
    }
  },
  mounted() {

  },
  methods: {
    init(row, options) {
      this.visible = true
      this.row = row
      this.options = options
    },
    reset() {
      this.$refs.myForm.resetForm()
    },
    // 表单提交
    typeFilterSubmit() {
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        // console.log(model, 'model')
        this.$emit('addTurnoverRules', model)
        this.visible = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__footer{
    text-align: center;
}
</style>
