<template>
  <component
    :is="currentCom"
    :mode="mode"
    :row-detail.sync="row"
    :options="options"
    @updateCom="updateCom"
  />
</template>
<script>
import HitStrategyList from './components/list.vue'
import HitStrategyAdd from './components/add.vue'
export default {
  name: 'HitStrategy',
  components: {
    HitStrategyList,
    HitStrategyAdd
  },
  data() {
    return {
      currentCom: 'HitStrategyList',
      mode: '',
      row: {},
      options: {}
    }
  },
  methods: {
    updateCom({ currentCom, mode, row, options }) {
      this.currentCom = currentCom
      this.mode = mode
      this.row = row
      this.options = options
    }
  }
}
</script>
<style lang="scss" scoped></style>
