<template>
  <div class="hit-strategy">
    <el-tabs v-model="activeName" class="tabs" @tab-click="handleClick">
      <el-tab-pane v-for="item in tabListConf" :key="item.component" :label="$t(item.label)" :name="item.component">
        <!-- <keep-alive :include="['HitStrategy']">
          <component :is="item.component" v-if="activeName === item.component" ref="comps" />
        </keep-alive> -->
        <ApplicationConfig v-if="item.component === 'ApplicationConfig'" :key="item.id"/>
        <HitStrategy v-else :key="item.id"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import ApplicationConfig from './applicationConfig/applicationConfig.vue'
import HitStrategy from './hitStrategy/hitStrategy.vue'
export default {
  components: {
    ApplicationConfig,
    HitStrategy
  },
  data() {
    return {
      activeName: sessionStorage.getItem('hitActiveName') || 'HitStrategy',
      tabListConf: [
        { id:0, label: this.$t('lang.gles.strategy.applicationConfig'), component: 'ApplicationConfig' },
        { id:1, label: this.$t('lang.gles.strategy.hitStrategy'), component: 'HitStrategy' }
      ]
    }
  },
  methods: {
    handleClick(tab, event) {
      if (tab.index === '0') {
        sessionStorage.setItem('hitActiveName', 'ApplicationConfig')
        // // 获取一下最新添加的策略
        // this.$nextTick(() => {
        //   this.$refs.comps[0].currentCom = 'appConfigList'
        //   this.$refs.comps[0].$refs.appConfig.getHitStrategyList()
        // })
      } else {
        // this.$refs.comps[1].currentCom = 'HitStrategyList'
        sessionStorage.setItem('hitActiveName', 'HitStrategy')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.hit-strategy {
  /deep/ .el-tabs__header {
    margin: 10px;
    padding: 10px 0;
    background-color: #fff;
    .el-tabs__item {
      width: 120px;
      font-weight: 800;
      text-align: center;
    }
  }
}
</style>
