import mapVerify from '@/utils/formValidate'

const filterFormater = (data, value) => {
  return data?.find((t) => t.value == value)?.label ?? value
}

export const getMoreQueryFormData = (that, options, row) => {
  const moreQueryData = [
    // 策略编码
    {
      name: 'strategyCode',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyCode')
    },
    // 策略名称
    {
      name: 'strategyName',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyName'),
      clearable: true
    },
    // 目标范围
    {
      name: 'targetRangeCode',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.targetRange'),
      clearable: true,
      data: options.targetRangeList || []
    },
    // 目标类型
    {
      name: 'targetTypeCode',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.targetType'),
      clearable: true,
      filterable: true,
      data: options.targetTypeList || []
    },
    // 优先规则
    {
      name: 'priorityRule',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.priorityRule'),
      clearable: true,
      filterable: true,
      data: options.priorityRuleList || []
    },
    // 状态
    {
      name: 'status',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.statusFlag'),
      clearable: true,
      filterable: true,
      data: that.statusFlagList || []
    }
  ]
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 策略编码
    {
      prop: 'strategyCode',
      sortable: true,
      label: that.$t('lang.gles.strategy.strategyCode'),
      width: 140,
      isShow: true
    },
    // 策略名称
    {
      prop: 'strategyName',
      label: that.$t('lang.gles.strategy.strategyName'),
      isShow: true
    },
    // 目标范围
    {
      prop: 'targetRangeCode',
      label: that.$t('lang.gles.strategy.targetRange'),
      formatter(row, column) {
        return filterFormater(that.targetRangeList, row[column])
      },
      isShow: true
    },
    // 目标类型
    {
      prop: 'targetTypeCode',
      label: that.$t('lang.gles.strategy.targetType'),
      width: 160,
      formatter(row, column) {
        return filterFormater(that.targetTypeList, row[column])
      },
      isShow: true
    },
    // 优先规则
    {
      prop: 'priorityRule',
      label: that.$t('lang.gles.strategy.priorityRule'),
      width: 160,
      formatter(row, column) {
        return filterFormater(that.priorityRuleList, row[column])
      },
      isShow: true
    },
    // 状态
    {
      prop: 'status',
      label: that.$t('lang.gles.common.statusFlag'),
      slotName: 'enableStatus',
      width: 120,
      isShow: true
    },
    // 备注
    {
      prop: 'remark',
      label: that.$t('lang.gles.strategy.remark'),
      isShow: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基本信息
export const getEditBaseFormData = (that, row, options) => {
  that.formModel = {
    targetRangeCode: row?.targetRangeCode,
    targetTypeCode: row?.targetTypeCode
  }
  const editBaseFormData = [
    // 策略编码
    {
      name: 'strategyCode',
      value: row.strategyCode || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyCode'),
      rules: mapVerify(['required', 'inputCode']),
      clearable: true,
      appendSlotName: 'inputCodeTipIcon',
      class: 'tip-icon'
    },
    // 策略名称
    {
      name: 'strategyName',
      value: row.strategyName || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyName'),
      placeholder: that.$t('lang.gles.common.inputLen200'),
      rules: mapVerify(['required', 'inputLen200']),
      clearable: true
    },
    // 备注
    {
      name: 'remark',
      value: row.remark || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.remark'),
      rules: mapVerify(['inputLen500']),
      clearable: true
    },
    // 目标范围
    {
      name: 'targetRangeCode',
      value: row.targetRangeCode || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.targetRange'),
      rules: mapVerify(['required']),
      clearable: true,
      data: options.targetRangeList || [],
      mchange: that.onTargetRangeFormChange
    },
    // 目标类型
    {
      name: 'targetTypeCode',
      value: row.targetTypeCode || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.targetType'),
      rules:
        that.formModel.targetRangeCode === 15 ? [] : mapVerify(['required']),
      clearable: true,
      disabled: [12, 13, 14, 15].includes(that.formModel.targetRangeCode),
      data: options.targetTypeList || [],
      mchange: that.onFormChange
    },
    // 优先规则
    {
      name: 'priorityRule',
      value: row.priorityRule || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.priorityRule'),
      rules:
        that.formModel.targetRangeCode === 15 ? [] : mapVerify(['required']),
      clearable: true,
      disabled: [15].includes(that.formModel.targetRangeCode),
      data: options.priorityRuleList || []
    }
  ]
  return editBaseFormData
}
// 目标范围table
export const getTargetTableData = (that) => {
  const targetTableData = [
    {
      prop: 'factoryId',
      label: that.$t('lang.gles.strategy.factory'),
      formatter(row, column) {
        return filterFormater(that.factoryList, row[column])
      }
    },
    {
      prop: 'workshopId',
      label: that.$t('lang.gles.strategy.workshop'),
      formatter(row, column) {
        return filterFormater(that.workshopList, row[column])
      }
    },
    {
      prop: 'warehouseId',
      label: that.$t('lang.gles.strategy.warehouse'),
      formatter(row, column) {
        return filterFormater(that.warehouseList, row[column])
      }
    },
    {
      prop: 'warehouseAreaId',
      label: that.$t('lang.gles.strategy.warehouseArea'),
      formatter(row, column) {
        return filterFormater(that.warehouseAreaList, row[column])
      }
    }
  ]
  if (
    (that.formModel.targetTypeCode === 11 &&
      that.formModel.targetRangeCode === 10) ||
    that.formModel.targetRangeCode === 13
  ) {
    targetTableData.push({
      prop: 'goodsPositionId',
      label: that.$t('lang.gles.strategy.fixGoodsPosition'),
      formatter(row, column) {
        return filterFormater(that.fixGoodsPositionList, row[column])
      }
    })
  } else {
    targetTableData.push({
      prop: 'factoryPositionId',
      label: that.$t('lang.gles.base.factoryPosition'),
      formatter(row, column) {
        return filterFormater(that.factoryPositionList, row[column])
      }
    })
  }
  // 分配规则
  targetTableData.push({
    prop: 'distributeRule',
    label: that.$t('lang.gles.strategy.distributeRule'),
    formatter(row, column) {
      return filterFormater(that.hitDistributeRuleList, row[column])
    }
  })
  targetTableData.forEach((item) => (item.showOverflowTooltip = true))
  return targetTableData
}
// 可编辑子table的form表单 ---目标范围
export const editTargetFormData = (that, row) => {
  const targetFormData = [
    // // 执行顺序
    // {
    //   name: 'executeSort',
    //   value: '',
    //   component: '',
    //   clearable: true,
    //   disabled: true
    // },
    {
      name: 'factoryId',
      value: '',
      component: 'elSelect',
      data: that.factoryList || [],
      clearable: true
    },
    {
      name: 'workshopId',
      value: '',
      component: 'elSelect',
      data: that.workshopList || [],
      clearable: true
    },
    {
      name: 'warehouseId',
      value: '',
      component: 'elSelect',
      data: that.warehouseList || [],
      clearable: true
    },
    {
      name: 'warehouseAreaId',
      value: '',
      component: 'elSelect',
      data: that.warehouseAreaList || [],
      clearable: true
    }
  ]
  if (
    (that.formModel.targetTypeCode === 11 &&
      that.formModel.targetRangeCode !== 14) ||
    that.formModel.targetRangeCode === 13
  ) {
    targetFormData.push({
      name: 'goodsPositionId',
      value: '',
      component: 'elSelect',
      data: that.fixGoodsPositionList || [],
      clearable: true
    })
  } else {
    targetFormData.push({
      name: 'factoryPositionId',
      value: '',
      component: 'RemoteSelect',
      labelText: 'factoryPositionCode',
      showLabel: 'factoryPositionCode',
      // data: that.factoryPositionList || [],
      clearable: true
    })
  }
  // 分配规则
  targetFormData.push({
    name: 'distributeRule',
    value: '',
    component: 'elSelect',
    data: that.hitDistributeRuleList || [],
    clearable: true
  })
  return targetFormData
}
export const getAddTargetRangeFormData = (that, options, { row }) => {
  const editTargetRangeFormData = [
    // 工厂
    {
      name: 'factoryId',
      value: row?.factoryId || '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.factory'),
      rules: mapVerify(['required']),
      clearable: true,
      data: that.factoryList || [],
      mchange: that.handleFactoryChange
    },
    // 车间
    {
      name: 'workshopId',
      value: row?.workshopId || '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.workshop'),
      rules: that.$refs.myRangeForm?.formModel.warehouseId
        ? []
        : mapVerify(['required']),
      clearable: true,
      data: that.workshopList || [],
      disabled: !!that.$refs.myRangeForm?.formModel.warehouseId,
      mchange: that.handleWorkshopChange
    },
    // 仓库
    {
      name: 'warehouseId',
      value: row?.warehouseId || '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.warehouse'),
      rules: that.$refs.myRangeForm?.formModel.workshopId
        ? []
        : mapVerify(['required']),
      clearable: true,
      data: that.warehouseList || [],
      disabled: !!that.$refs.myRangeForm?.formModel.workshopId,
      mchange: that.handleWarehouseChange
    },
    // 库区
    {
      name: 'warehouseAreaId',
      value: row?.warehouseAreaId || '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.warehouseArea'),
      rules: mapVerify(['required']),
      clearable: true,
      data: that.warehouseAreaList || [],
      mchange: that.handleWarehouseAreaChange
    }
  ]
  if (
    (options.targetTypeCode === 11 && options.targetRangeCode === 10) ||
    options.targetRangeCode === 13
  ) {
    editTargetRangeFormData.push({
      name: 'goodsPositionId',
      value: row?.goodsPositionId || [],
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.fixGoodsPosition'),
      clearable: true,
      multiple: true,
      data: that.fixGoodsPositionList || [],
      mchange: that.handleChange,
      condition(formModel) {
        // 来控制是否展示
        return !formModel.distributeRule
      }
    })
  } else {
    editTargetRangeFormData.push({
      name: 'factoryPositionId',
      value: row?.factoryPositionId || [],
      span: 20,
      component: 'RemoteSelect',
      labelText: 'factoryPositionCode',
      showLabel: 'factoryPositionCode',
      label: that.$t('lang.gles.base.factoryPosition'),
      clearable: true,
      multiple: true,
      data: that.factoryPositionList || [],
      mchange: that.handleChange,
      condition(formModel) {
        // 来控制是否展示
        return !formModel.distributeRule
      }
    })
  }
  editTargetRangeFormData.push({
    name: 'distributeRule',
    value: row?.distributeRule || '',
    span: 20,
    component: 'elSelect',
    label: that.$t('lang.gles.strategy.distributeRule'),
    appendSlotName: 'inputCodeTipIcon',
    clearable: true,
    data: options.options?.distributeRuleList || [],
    mchange: that.handleChange,
    class: 'tip-icon',
    condition(formModel) {
      // 来控制是否展示
      if (
        (options.targetTypeCode === 11 && options.targetRangeCode !== 14) ||
        options.targetRangeCode === 13
      ) {
        return !formModel.goodsPositionId?.length
      }
      return !formModel.factoryPositionId?.length
    }
  })
  return editTargetRangeFormData
}
export const getAddTurnoverRulesFormData = (that, options) => {
  const turnoverRulesFormData = [
    // 周转属性
    {
      name: 'turnoverProp',
      value: '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.turnoverProp'),
      rules: mapVerify(['required']),
      clearable: true,
      data: options.turnoverPropList || []
    },
    // 周转规则
    {
      name: 'turnoverRule',
      value: '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.hit.turnoverRule'),
      rules: mapVerify(['required']),
      clearable: true,
      data: options.turnoverRuleList || []
    }
  ]
  return turnoverRulesFormData
}
// 周转规则table
export const getTurnoverRulesTableData = (that) => {
  const turnoverRulesTableData = [
    {
      isShow: true,
      prop: 'turnoverProp',
      label: that.$t('lang.gles.strategy.turnoverProp'),
      component: 'elSelect',
      rules: mapVerify(['required']),
      data: that.cacheTurnoverPropList || [],
      formatter(row, column) {
        return filterFormater(that.cacheTurnoverPropList, row[column])
      }
    },
    {
      isShow: true,
      prop: 'turnoverRule',
      label: that.$t('lang.gles.strategy.hit.turnoverRule'),
      component: 'elSelect',
      rules: mapVerify(['required']),
      data: that.turnoverRuleList || [],
      formatter(row, column) {
        return filterFormater(that.turnoverRuleList, row[column])
      }
    }
  ]
  return turnoverRulesTableData
}
// 过滤条件
export const getFilterConditionFormData = (that, row) => {
  // console.log(that,"thissssss",row);
  const filterConditionFormData = [
    // // 机器人系列
    // {
    //   name: 'robotType',
    //   value: row.robotType || '',
    //   span: 6,
    //   component: 'elSelect',
    //   label: that.$t('lang.gles.strategy.robotSeries'),
    //   rules: mapVerify(['required']),
    //   clearable: true,
    //   data: that.robotTypeList || []
    // },
    // 机器人型号
    {
      name: 'robotModel',
      value: row.robotModel || [],
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.robotModel'),
      rules: mapVerify(['required']),
      clearable: true,
      multiple: true,
      data: that.robotModelList || []
    },
    // 任务类型
    {
      name: 'taskType',
      value: row.taskType || 20,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.taskType'),
      rules: mapVerify(['required']),
      clearable: true,
      data: that.taskTypeList.filter((item) => Number(item.value) === 20) || []
    },
    // 目标范围 --在途的库区
    {
      name: 'targetScope',
      value: row.targetScope || [],
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.targetRange'),
      rules: mapVerify(['required']),
      clearable: true,
      multiple: true,
      data: that.warehouseAreaList || []
    },
    // 分配规则
    {
      name: 'hitDistributeRule',
      value: row.hitDistributeRule || '',
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.distributeRule'),
      rules: mapVerify(['required']),
      clearable: true,
      data: that.hitOnTheWayDistributeRuleList || []
    }
  ]
  // filterConditionFormData.forEach(item => {
  //   if (!item.mchange) {
  //     item.mchange = that.onFormChange
  //   }
  // })
  return filterConditionFormData
}

// 限制条件
export const getLimitConditionFormData = (that, row, options) => [
  // 等级状态 开启checkbox
  {
    name: 'materialEnableGrade',
    value: row.materialEnableGrade ?? 0,
    span: 2
    // appendComponent: 'materialEnableGradeSlot'
    // component: 'elCheckboxGroup',
    // data: [{ label: that.$t('lang.gles.common.gradeStatus'), value: 1 }],
    // mchange: that.handleGradeStatusLimitChange
  },
  // 等级来源
  {
    name: 'materialGradeSource',
    value: row.materialGradeSource ?? 1,
    span: 6,
    component: 'elSelect',
    data: that.materialGradeSourceList,
    condition(formModel) {
      return !!that.gradeLimitCondition?.materialEnableGrade
    },
    mchange: that.handleGradeStatusLimitChange
  },
  // 等级状态 下拉框
  {
    name: 'materialGradeStatus',
    value: row.materialGradeStatus || [],
    span: 6,
    component: 'elSelect',
    multiple: true,
    data: that.gradeStatusList,
    condition(formModel) {
      return !!that.gradeLimitCondition?.materialEnableGrade && that.gradeLimitCondition.materialGradeSource === 1
    },
    mchange: that.handleGradeStatusLimitChange
  }
]
