<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="strategyCode"
      :value.sync="baseFormModel.strategyCode"
      :placeholder="$t('lang.gles.strategy.pleaseInputStrategyCode')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t("lang.gles.common.addNew") }}
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-circle-check"
          :disabled="!selectValue.length"
          @click="batchChangeStatus(0)"
        >
          {{ $t("lang.gles.common.enable") }}
        </el-button>
        <el-button
          type="danger"
          icon="el-icon-circle-close"
          :disabled="!selectValue.length"
          @click="batchChangeStatus(1)"
        >
          {{ $t("lang.gles.common.disable") }}
        </el-button>
        <!-- 列设置 -->
        <!-- <column-config :list.sync="columnConfigList" /> -->
      </div>
      <m-table
        ref="mTable"
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @copyItem="copyItem"
        @delItem="delItem"
        @pageChange="pageChange"
        @selection-change="(val) => (selectValue = val)"
      >
        <template #enableStatus="{ row }">
          <el-switch
            :value="row.status"
            class="switch"
            :active-text="$t('lang.gles.common.enable')"
            :inactive-text="$t('lang.gles.common.disable')"
            :active-value="0"
            :inactive-value="1"
            @click.native="changeStatus(row)"
          />
        </template>
      </m-table>
    </el-card>
  </div>
</template>
<script>
// import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import { mapState, mapActions } from 'vuex'
import { getSerialBizCode } from '@/utils/getDictPramas'
export default {
  name: 'HitStrategyList',
  components: {
    // ColumnConfig,
    QueryView,
    MTable
  },
  mixins: [listMixins],
  data() {
    return {
      tableData: [],
      baseFormModel: {
        strategyCode: ''
      },
      formModel: {},
      selectValue: [],
      pageData: {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0
      }
    }
  },
  computed: {
    ...mapState('strategyManage', ['targetRangeList', 'targetTypeList', 'priorityRuleList']),
    ...mapState('base', ['statusFlagList']),
    moreQueryData: function() {
      const options = {
        targetRangeList: this.targetRangeList,
        targetTypeList: this.targetTypeList,
        priorityRuleList: this.priorityRuleList
      }
      return getMoreQueryFormData(this, options, { ...this.moreQueryFormModel })
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      const options = {
        targetRangeList: this.targetRangeList,
        targetTypeList: this.targetTypeList
      }
      return getSearchTableItem(this, options)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    if (!this.statusFlagList?.length) {
      this.$store.dispatch('base/getStatusFlagList')
    }
    this.doSearch(this.baseFormModel)
  },
  mounted(){
    this.getTargetRangeList()
    this.getTargetTypeList()
    this.getPriorityRuleList()
    this.tableExtendConfig = {
      ...this.tableExtendConfig,
      checkBox: true,
      operateWidth: '160px',
      operate: [
        {
          event: 'editItem',
          label: this.$t('lang.gles.common.edit')
        },
        {
          event: 'copyItem',
          label: this.$t('lang.gles.common.copy')
        },
        {
          event: 'delItem',
          confirm: true,
          confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
          label: this.$t('lang.gles.common.delete'),
          customClass: 'danger'
        }
      ]
    }
  },
  methods: {
    ...mapActions('strategyManage', ['getTargetRangeList', 'getTargetTypeList', 'getPriorityRuleList']),
    // 查询
    async doSearch(params, api = 'queryHitStrategy') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.strategyManage(api, this.pageData),
          params
        )
        if (code) return
        this.$store.commit(types.IS_LOADING, false)
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    // 新增
    updateCom() {
      const options = {
        targetRangeList: this.targetRangeList,
        targetTypeList: this.targetTypeList,
        priorityRuleList: this.priorityRuleList
      }
      getSerialBizCode('hitStrategyCode', 'strategyCode').then((data) => {
        this.$emit('updateCom', {
          currentCom: 'HitStrategyAdd',
          mode: 'add',
          row: { strategyCode: data },
          options
        })
      })
    },
    editItem({ row }) {
      const options = {
        targetRangeList: this.targetRangeList,
        targetTypeList: this.targetTypeList,
        priorityRuleList: this.priorityRuleList
      }
      this.$emit('updateCom', {
        currentCom: 'HitStrategyAdd',
        mode: 'edit',
        row,
        options
      })
    },
    // 复制
    copyItem({ row }) {
      const options = {
        targetRangeList: this.targetRangeList,
        targetTypeList: this.targetTypeList,
        priorityRuleList: this.priorityRuleList
      }
      getSerialBizCode('hitStrategyCode', 'strategyCode').then((data) => {
        const row_detail = { ...row, strategyCode: data }
        this.$emit('updateCom', {
          currentCom: 'HitStrategyAdd',
          mode: 'copy',
          row: row_detail,
          options
        })
      })
    },
    // 启用/禁用
    async batchChangeStatus(status) {
      const selectData = this.selectValue
      if (selectData.length < 1) {
        this.$message({
          type: 'error',
          message: this.$t('lang.gles.strategy.atLeastOneRecord')
        })
        return
      }
      const strategyIdList = selectData.map((item) => item.id)
      if (!status) {
        const { code } = await this.$httpService(
          this.$apiStore.strategyManage('enableHitStrategy'),
          { strategyIdList }
        )
        if (code) return
        this.$message.success(this.$t('lang.gles.strategy.enableSuccess'))
      } else {
        const { code } = await this.$httpService(
          this.$apiStore.strategyManage('disableHitStrategy'),
          { strategyIdList }
        )
        if (code) return
        this.$message.success(this.$t('lang.gles.strategy.disableSuccess'))
      }
      selectData.forEach(cv => {
        cv.status = status
      })
      this.$refs.mTable.$refs.myTable.clearSelection()
      this.selectValue = []
      //   this.doSearch(this.baseFormModel)
    },
    // 1-禁用 0-启用
    async changeStatus(row) {
      if (row.status) {
        const { code } = await this.$httpService(
          this.$apiStore.strategyManage('enableHitStrategy'),
          { strategyIdList: [row.id] }
        )
        if (code) return
        this.$message.success(this.$t('lang.gles.strategy.enableSuccess'))
      } else {
        const { code } = await this.$httpService(
          this.$apiStore.strategyManage('disableHitStrategy'),
          { strategyIdList: [row.id] }
        )
        if (code) return
        this.$message.success(this.$t('lang.gles.strategy.disableSuccess'))
      }
      row.status = row.status ? 0 : 1
      //   this.doSearch()
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    // 更多查询
    handleMoreSearch(data) {
      // console.log(event)
      this.formModel = { ...data }
      this.doSearch({ ...data }, 'queryHitStrategy')
    },
    // 删除命中策略
    async delItem({ row }) {
      const { code } = await this.$httpService(
        this.$apiStore.strategyManage('deleteHitStrategy'),
        { strategyId: row.id }
      )
      if (code !== 0) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      if (this.tableData.length < 2) this.resetPageData()
      this.doSearch(this.baseFormModel)
    }
  }
}
</script>
