<template>
  <div class="basic-data-add">
    <el-card class="mb10">
      <div class="form_title b mb15">
        {{ $t('lang.gles.common.basicInfo') }}
      </div>
      <m-form
        ref="myForm"
        :form-data="basicFormData"
        label-position="right"
        :label-width="120"
        :extend-config="extendFormConfig"
      >
        <template #inputCodeTipIcon>
          <el-tooltip
            popper-class="workshop_code_tip-icon"
            effect="dark"
            :content="$t(autoGeneCodeTip)"
            placement="right"
          >
            <i class="el-icon-tip el-icon-question tip" />
          </el-tooltip>
        </template>
      </m-form>
      <el-button type="primary" class="ml20" @click="onCancel">
        {{ $t('lang.gles.common.back') }}
      </el-button>
    </el-card>
    <el-card v-if="isShowAll" class="add-card">
      <div class="form_title b mb15">
        {{ $t('lang.gles.strategy.strategyDetail') }}
      </div>
      <!-- 周转规则 -->
      <div v-if="showTargetRange" class="turnoverRules">
        <el-divider content-position="left">
          {{ $t('lang.gles.strategy.hit.turnoverRule') }}
        </el-divider>
        <div class="btns ml30">
          <el-button
            type="text"
            icon="el-icon-circle-plus"
            @click="addTurnoverRules"
          >
            {{ $t('lang.gles.strategy.add') }}
          </el-button>
          <el-button
            type="text"
            style="color: #f56c6c"
            icon="el-icon-delete-solid"
            @click="batchDelTurnoverRules"
          >
            {{ $t('lang.gles.common.batchDelete') }}
          </el-button>
          <edit-table
            ref="turnoverRulesEditTable"
            :width="'80%'"
            :table-data.sync="turnoverRulesTableData"
            :table-item="turnoverRulesTableItem"
            :extend-config="extendTableConfig"
            @editRowItem="editRow"
            @removeRowItem="delTurnoverRulesRow"
            @selection-change="(val) => (selectTurnoverRules = val)"
          />
        </div>
      </div>
      <!-- 目标范围 -->
      <div v-if="showTargetRange" class="targetRange">
        <el-divider content-position="left">
          {{ $t('lang.gles.strategy.targetRange') }}
        </el-divider>
        <div
          v-if="formModel.targetRangeCode === 11 && !!formModel.targetTypeCode"
          class="ml30"
        >
          <el-form ref="form1" :model="ruleForm" label-width="80px">
            <el-form-item :label="$t('lang.gles.strategy.distributeRule')">
              <el-select
                v-model="ruleForm.distributeRule"
                :placeholder="$t('lang.gles.common.pleaseSelect')"
                clearable
              >
                <el-option
                  :label="$t('lang.gles.strategy.hit.stockOutSequential')"
                  :value="20"
                />
                <el-option
                  :label="$t('lang.gles.strategy.hit.clearStock')"
                  :value="21"
                />
              </el-select>
              <el-tooltip
                popper-class="workshop_code_tip-icon"
                effect="dark"
                :content=" formModel.targetTypeCode === 10 ? $t('lang.gles.strategy.setTheOutboundOrderForFactoryPosition') : $t('lang.gles.strategy.setTheOutboundOrderForGoodsPosition')"
                placement="right"
              >
                <i class="el-icon-tip el-icon-question ml5" />
              </el-tooltip>
            </el-form-item>
          </el-form>
        </div>
        <div v-else class="btns ml30">
          <el-button
            type="text"
            icon="el-icon-circle-plus"
            @click="addTargetRange"
          >
            {{ $t('lang.gles.strategy.add') }}
          </el-button>
          <el-button
            type="text"
            style="color: #f56c6c"
            icon="el-icon-delete-solid"
            @click="batchDelRange"
          >
            {{ $t('lang.gles.common.batchDelete') }}
          </el-button>
          <!-- <m-edit-table
            ref="targetRangeEditTable"
            border
            :table-data="targetRangeTableData"
            :extend-config="extendTargetTableConfig"
            :table-item="targetRangeTableItem"
            :form-data="targetRangeTableFormData"
            :page-data="null"
            @delRangeRow="delRangeRow"
            @mEditTableEditRow="editTargetRow"
            @upRow="upRow"
            @downRow="downRow"
            @selection-change="(val) => (selectTargetRange = val)"
          /> -->
          <m-table
            ref="targetRangeEditTable"
            border
            :table-data="targetRangeTableData"
            :extend-config="extendTargetTableConfig"
            :table-item="targetRangeTableItem"
            :page-data="null"
            @delRow="delRangeRow"
            @mEditTableEditRow="editTargetRow"
            @upRow="upRow"
            @downRow="downRow"
            @selection-change="(val) => (selectTargetRange = val)"
          />
        </div>
      </div>
      <!-- 在途库存---过滤条件 -->
      <div v-if="formModel.targetRangeCode === 15" class="filterCondition">
        <el-divider content-position="left">
          {{ $t('lang.gles.strategy.filterConditions') }}
        </el-divider>
        <m-form
          ref="myFilterForm"
          :form-data="filterConditionFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        />
      </div>
      <!-- 限制条件 -->
      <div class="limit-condition">
        <el-divider content-position="left">
          {{ $t('限制条件') }}
        </el-divider>
        <div class="ml30">
          <m-form
            ref="gradeLimitConditionForm"
            :form-data="limitConditionFormData"
            label-position="right"
            :label-width="0"
            :extend-config="extendFormConfig"
          >
            <template #appendComponent>
              <el-checkbox v-model="gradeLimitCondition.materialEnableGrade" :true-label="1" :false-label="0" @change="handleEnableGradeChange">
                {{ $t('lang.gles.common.gradeStatus') }}
              </el-checkbox>
            </template>
          </m-form>
        </div>
      </div>
      <div class="footer-btns">
        <el-button @click="onCancel">
          {{ $t('lang.gles.common.cancel') }}
        </el-button>
        <el-button type="primary" @click="onSubmit">
          {{ $t('lang.gles.common.confirm') }}
        </el-button>
      </div>
    </el-card>
    <!-- 新增目标范围弹窗 -->
    <add-target-range ref="addTargetRange" @addRange="updateTargetRangeList" />
    <!-- 新增周转规则弹窗 -->
    <add-turnover-rules
      ref="addTurnoverRules"
      @addTurnoverRules="updateTurnoverRulesList"
    />
  </div>
</template>
<script>
import _ from 'lodash'
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import AddTargetRange from './addTagetRange.vue'
import EditTable from '@/components/tableForm/editBase'
// import myTransform from '@/libs_sz/utils/transform'
import {
  getEditBaseFormData,
  getTargetTableData,
  editTargetFormData,
  getTurnoverRulesTableData,
  getFilterConditionFormData,
  getLimitConditionFormData
} from '../data'
import { mapActions, mapState } from 'vuex'
import AddTurnoverRules from './addTurnoverRules'

export default {
  name: 'AddShelfStrategy',
  components: {
    MForm,
    EditTable,
    AddTargetRange,
    AddTurnoverRules
  },
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      targetRangeTableData: [],
      turnoverRulesTableData: [],
      filterCondition: {},
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      extendTargetTableConfig: {
        sortNum: true,
        checkBox: true,
        executeSort: true,
        operate: [
          {
            event: 'upRow',
            label: this.$t('lang.gles.strategy.moveUp')
          },
          {
            event: 'downRow',
            label: this.$t('lang.gles.strategy.moveDown')
          },
          {
            event: 'mEditTableEditRow',
            label: this.$t('lang.gles.common.edit')
          },
          {
            event: 'delRow',
            label: this.$t('lang.gles.common.delete'),
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
            customClass: 'danger'
          }
        ]
      },
      extendTableConfig: {
        sortNum: true,
        checkBox: true,
        executeSort: true,
        sortCondition() {
          return true
        },
        operateWidth: 260
      },
      selectTurnoverRules: [],
      selectTargetRange: [],
      ruleForm: {
        distributeRule: ''
      },
      editTargetRangeData: {},
      fixGoodsPositionList: [],
      containerTypeCodeList: [],
      goodPositionNumList: [],
      turnoverPropList: [],
      cacheTurnoverPropList: [],
      dataSave: {},
      gradeLimitCondition: {
        materialEnableGrade: 0,
        materialGradeSource: 1,
        materialGradeStatus: []
      }
    }
  },
  computed: {
    ...mapState('strategyManage', ['hitDistributeRuleList', 'turnoverRuleList', 'robotTypeList', 'robotModelList', 'taskTypeList', 'hitOnTheWayDistributeRuleList', 'materialGradeSourceList']),
    ...mapState('base', [
      'factoryList',
      'workshopList',
      'warehouseList',
      'warehouseAreaList',
      'factoryPositionList',
      'gradeStatusList'
    ]),
    basicFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...row }, { ...this.options })
    },
    targetRangeTableItem() {
      return getTargetTableData(this)
    },
    targetRangeTableFormData() {
      return editTargetFormData(this, { ...this.editTargetRangeData })
    },
    turnoverRulesTableItem() {
      return getTurnoverRulesTableData(this)
    },
    filterConditionFormData() {
      const row = this.rowDetail || {}
      return getFilterConditionFormData(this, {
        ...row,
        ...this.filterCondition
      })
    },
    // 限制条件
    limitConditionFormData() {
      const row = this.rowDetail || {}
      return getLimitConditionFormData(this, { ...row, ...this.gradeLimitCondition })
    },
    // card 展示
    isShowAll() {
      if (
        (this.formModel.targetRangeCode === 10 &&
          !this.formModel.targetTypeCode) ||
        (this.formModel.targetRangeCode === 11 &&
          !this.formModel.targetTypeCode) ||
        !this.formModel.targetRangeCode
      ) {
        return false
      }
      return true
    },
    // 目标范围的isShow
    showTargetRange() {
      return (
        !!this.formModel.targetRangeCode &&
        this.formModel.targetRangeCode !== 15
      )
    }
  },
  watch: {
    // filterConditionFormData() {
    //   this.$nextTick(() => {
    //     this.$refs?.myFilterForm?.changeFormValue(this.filterCondition ?? {})
    //   })
    // },
    limitConditionFormData() {
      this.$nextTick(() => {
        this.$refs?.gradeLimitConditionForm?.changeFormValue(this.gradeLimitCondition ?? {})
      })
    },
    formModel: {
      handler(formModel) {
        this.targetRangeTableData = []
        this.turnoverRulesTableData = []
        this.ruleForm = {
          distributeRule: ''
        }
      }
    }
  },
  created() {
    this.initSelectList()
    if (this.mode === 'edit' || this.mode === 'copy') {
      this.getHitStrategyDetail()
      this.dataSave = this._.cloneDeep(this.rowDetail)
    }
  },
  methods: {
    ...mapActions('strategyManage', [
      'getHitDistributeRuleList',
      'getTurnOverRuleList',
      'getRobotTypeList',
      'getRobotModelList',
      'getTaskTypeList',
      'getOnTheWayDistributeRuleList'
    ]),
    initSelectList() {
      this.$store.dispatch('base/getFactoryList')
      this.$store.dispatch('base/getWorkshopList')
      this.$store.dispatch('base/getWarehouseList')
      this.$store.dispatch('base/getWarehouseAreaList')
      this.$store.dispatch('base/getFactoryPositionList')
      this.getHitDistributeRuleList()
      this.getFixGoodsPositionList()
      this.getTurnOverRuleList()
      // this.getTurnoverPropList()
      this.getTurnProp()
      this.getRobotTypeList()
      this.getRobotModelList()
      this.getTaskTypeList()
      this.getOnTheWayDistributeRuleList()
      if (!this.materialGradeSourceList?.length) {
        this.$store.dispatch('strategyManage/getMaterialGradeSourceList')
      }
      if (!this.gradeStatusList?.length) {
        this.$store.dispatch('base/getGradeStatusList')
      }
    },
    // 根据目标范围不同显示不同目标类型
    onTargetRangeFormChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.$emit('update:rowDetail', this._.cloneDeep(formModel))
      if (val === 12) changeFormValue({ targetTypeCode: 10 })
      if (val === 13) changeFormValue({ targetTypeCode: 11 })
      if (val === 14) changeFormValue({ targetTypeCode: 12 })
      if (val === 15) changeFormValue({ targetTypeCode: '', priorityRule: '' })
    },
    handleGradeStatusLimitChange(val, formItem) {
      this.$set(this.gradeLimitCondition, formItem.name, val)
    },
    handleEnableGradeChange(val) {
      if (val) {
        this.gradeLimitCondition.materialGradeSource = 1
      } else {
        this.gradeLimitCondition.materialGradeSource = null
        this.gradeLimitCondition.materialGradeStatus = null
      }
    },
    // 获取周转属性的下拉数据
    getTurnoverPropList() {
      return new Promise((resolve, reject) => {
        this.$httpService(
          this.$apiStore.base('queryDictByCode'),
          { dictCode: 'batchPropertyLabel' }
        ).then(({ code, data }) => {
          if (code) reject()
          resolve(data)
        })
      })
      /* if (code) return
      const { baseDictDetails } = data
      this.turnoverPropList = baseDictDetails.map((item) => {
        return { label: this.$t(item.detailName), value: item.detailCode }
      })
      this.cacheTurnoverPropList = baseDictDetails.map((item) => {
        return { label: this.$t(item.detailName), value: item.detailCode }
      }) */
    },
    getHitStrategyTurnoverRule() {
      return new Promise((resolve, reject) => {
        this.$httpService(
          this.$apiStore.base('queryDictByCode'),
          { dictCode: 'hitStrategyTurnoverRule' }
        ).then(({ data, code }) => {
          if (code) reject()
          resolve(data)
        })
      })
    },
    getTurnProp() {
      console.log('getTurnProp')
      Promise.all([this.getTurnoverPropList(), this.getHitStrategyTurnoverRule()]).then((values) => {
        const { baseDictDetails: propDetails } = values[0]
        const { baseDictDetails: ruleDetails } = values[1]
        this.turnoverPropList = [...propDetails, ...ruleDetails].map((item) => {
          return { label: this.$t(item.detailName), value: item.detailCode }
        })
        this.cacheTurnoverPropList = _.cloneDeep(this.turnoverPropList)
        // this.cacheTurnoverPropList = propDetails.map((item) => {
        //   return { label: this.$t(item.detailName), value: item.detailCode }
        // })
      }).catch((error) => {
        console.log('error:', error)
      })
    },
    /**
     * 获取固定货位列表
     */
    async getFixGoodsPositionList(params) {
      const { data, code } = await this.$httpService(
        this.$apiStore.base('getFixGoodsPositionByWarehouseAreaId')
      )
      if (code) return
      this.fixGoodsPositionList = data.map((item) => {
        return { label: item.code, value: item.id }
      })
    },
    // edit或copy 下获取详情
    async getHitStrategyDetail() {
      const { code, data } = await this.$httpService(
        this.$apiStore.strategyManage('detailHit'),
        { strategyId: this.rowDetail.id }
      )
      if (code) return
      // 回显
      this.targetRangeTableData = data.targetRangeVoList
      if (data.targetRangeCode === 11) {
        this.ruleForm.distributeRule =
          data.targetRangeVoList[0]?.distributeRule || ''
      }
      this.turnoverRulesTableData = data.hitTurnoverRuleVoList
      if (data.targetRangeCode === 15) {
        const condition =
          data.strategyHitFilterConditionConfigVoList &&
          data.strategyHitFilterConditionConfigVoList[0]
        this.filterCondition = {
          ...condition,
          targetScope: condition.targetScope
            ? condition.targetScope.split(',').map((item) => Number(item))
            : [],
          robotModel: condition.robotModel
            ? condition.robotModel.split(',')
            : []
        }
        this.$refs?.myFilterForm?.changeFormValue(this.filterCondition ?? {})
      }
      // 回显 限制条件
      if (!data.hitGradeLimitConfigVo) {
        this.gradeLimitCondition.materialEnableGrade = 0
      } else {
        this.gradeLimitCondition = {
          ...data.hitGradeLimitConfigVo,
          materialGradeStatus: (data.hitGradeLimitConfigVo?.materialGradeStatus?.split(',') || []).map(t => Number(t))
        }
      }
    },
    // 添加目标范围
    addTargetRange() {
      const options = {
        distributeRuleList: this.hitDistributeRuleList
      }
      this.$refs.addTargetRange.init({ ...this.formModel, options }, 'add')
    },
    // 删除
    delRangeRow({ $index }) {
      this.targetRangeTableData.splice($index, 1)
      // this.$refs.targetRangeEditTable.removeRow(row)
    },
    editTargetRow(row) {
      const options = {
        // factoryList: this.factoryList,
        // workshopList: this.workshopList,
        // warehouseList: this.warehouseList,
        // warehouseAreaList: this.warehouseAreaList,
        // factoryPositionList: this.factoryPositionList,
        distributeRuleList: this.hitDistributeRuleList
      }
      this.$refs.addTargetRange.init(
        { ...this.formModel, options },
        'edit',
        row
      )
    },
    // // 上移，下移
    upRow({ $index, row }) {
      if ($index > 0) {
        const upDate = this.targetRangeTableData[$index - 1]
        this.targetRangeTableData.splice($index - 1, 1)
        this.targetRangeTableData.splice($index, 0, upDate)
      } else {
        this.$message({
          type: 'warning',
          message: this.$t('lang.gles.strategy.firstRecordNotBeMoveUp')
        })
      }
    },
    downRow({ $index, row }) {
      if ($index + 1 === this.targetRangeTableData.length) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.gles.strategy.lastRecordNotBeMoveUp')
        })
      } else {
        const downDate = this.targetRangeTableData[$index + 1]
        this.targetRangeTableData.splice($index + 1, 1)
        this.targetRangeTableData.splice($index, 0, downDate)
      }
    },
    updateTargetRangeList(val, index) {
      // console.log(val,"vvvvvv")
      if (typeof index === 'undefined') {
        val.forEach((item) => {
          this.targetRangeTableData.push(item)
        })
      } else {
        this.targetRangeTableData.splice(index, 1, ...val)
      }
    },
    batchDelRange() {
      const selectData = this.selectTargetRange
      if (selectData.length < 1) {
        this.$message({
          type: 'error',
          message: this.$t('lang.gles.strategy.atLeastOneRecordCouldBeDelete')
        })
        return
      }
      selectData.forEach((item) => {
        delete item.isEdit
        delete item.isNew
        const findIndex = this.targetRangeTableData.findIndex((c) => {
          return JSON.stringify(c) === JSON.stringify(item)
        })
        // 清楚当前的tableData
        this.targetRangeTableData.splice(findIndex, 1)
      })
    },
    // 添加周转规则
    addTurnoverRules() {
      // console.log(this.turnoverRulesTableData)
      // 添加周转规则之前过滤掉已添加的周转属性
      const turnoverPropCode = this.turnoverRulesTableData.map(
        (item) => item.turnoverProp
      )
      turnoverPropCode.length > 0 &&
        turnoverPropCode.forEach((item) => {
          this.turnoverPropList = this.turnoverPropList.filter(
            (cv) => String(cv.value) !== String(item)
          )
        })
      const options = {
        turnoverPropList: this.turnoverPropList,
        turnoverRuleList: this.turnoverRuleList
      }
      this.$refs.addTurnoverRules.init({ ...this.formModel }, options)
    },
    updateTurnoverRulesList(val) {
      this.turnoverRulesTableData.push({ ...val })
    },
    // 删除周转规则
    delTurnoverRulesRow({ $index, row }) {
      // this.turnoverRulesTableData.splice($index, 1)
      this.turnoverPropList = this.cacheTurnoverPropList
    },
    // 批量删除周转规则
    batchDelTurnoverRules() {
      const selectData = this.selectTurnoverRules
      if (selectData.length < 1) {
        this.$message({
          type: 'error',
          message: this.$t('lang.gles.strategy.atLeastOneRecordCouldBeDelete')
        })
        return
      }
      selectData.forEach((item) => {
        delete item.isEdit
        delete item.isNew
        const findIndex = this.turnoverRulesTableData.findIndex((c) => {
          return JSON.stringify(c) === JSON.stringify(item)
        })
        // 清楚当前的tableData
        this.turnoverRulesTableData.splice(findIndex, 1)
      })
      this.turnoverPropList = this.cacheTurnoverPropList
    },
    editRow(row) {
      console.log(row, '------')
      // this.editTargetRangeData = row
    },
    onSubmit() {
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        const params = { ...this.rowDetail, ...model }
        // 在途库存--命中策略
        if (params.targetRangeCode === 15) {
          this.$refs.myFilterForm
            .getValidateFormModel()
            .then(async(filterModel) => {
              params.strategyHitFilterConditionConfigDtoList = [
                {
                  robotType: filterModel.robotType,
                  robotModel: filterModel.robotModel.join(','),
                  taskType: filterModel.taskType,
                  targetScope: filterModel.targetScope.join(','),
                  hitDistributeRule: filterModel.hitDistributeRule
                }
              ]
              const gradeLimitCondition = this.getGradeLimitCondition()
              if (!gradeLimitCondition) return
              params.strategyHitGradeLimitConfigDto = gradeLimitCondition
              if (this.mode === 'edit') {
                params.strategyId = this.dataSave.id
                delete params.id
              }
              if (this.mode === 'copy') {
                delete params.id
              }
              console.log(params, 'params+++')
              this.mode === 'add'
                ? await this.insert(params)
                : this.mode === 'edit'
                  ? await this.update(params)
                  : await this.copy(params)
            })
        } else {
          const targetRangeTableData =
            this.targetRangeTableData.length > 0
              ? this.targetRangeTableData.map((item, index) => {
                return { ...item, executeSort: index + 1 }
              })
              : []
          const turnoverRulesTableData = this.$refs.turnoverRulesEditTable
            ? this.$refs.turnoverRulesEditTable
              .getTableData()
              .map((item, index) => {
                return { ...item, executeSort: index + 1 }
              })
            : []

          const hitParams = {
            ...params,
            targetRangeDtoList: this.ruleForm.distributeRule
              ? [{ distributeRule: this.ruleForm.distributeRule }]
              : targetRangeTableData,
            hitTurnoverRuleDtoList: turnoverRulesTableData
          }
          if (this.mode === 'edit') {
            hitParams.strategyId = this.dataSave.id
            hitParams.targetRangeDtoList = hitParams.targetRangeDtoList.map(
              (item) => {
                return {
                  executeSort: item.executeSort,
                  factoryId: item.factoryId,
                  workshopId: item.workshopId,
                  warehouseId: item.warehouseId,
                  warehouseAreaId: item.warehouseAreaId,
                  factoryPositionId: Array.isArray(item.factoryPositionId) ? (item.factoryPositionId[0] ? item.factoryPositionId[0] : null) : item.factoryPositionId,
                  goodsPositionId: Array.isArray(item.goodsPositionId) ? (item.goodsPositionId[0] ? item.goodsPositionId[0] : null) : item.goodsPositionId,
                  distributeRule: item.distributeRule
                }
              }
            )
            hitParams.hitTurnoverRuleDtoList =
              hitParams.hitTurnoverRuleDtoList.map((item) => {
                return {
                  executeSort: item.executeSort,
                  turnoverProp: item.turnoverProp,
                  turnoverRule: item.turnoverRule
                }
              })
            delete hitParams.id
          }
          if (this.mode === 'copy') {
            delete hitParams.id
            hitParams.targetRangeDtoList = hitParams.targetRangeDtoList.map(
              (item) => {
                return {
                  executeSort: item.executeSort,
                  factoryId: item.factoryId,
                  workshopId: item.workshopId,
                  warehouseId: item.warehouseId,
                  warehouseAreaId: item.warehouseAreaId,
                  factoryPositionId: Array.isArray(item.factoryPositionId) ? (item.factoryPositionId[0] ? item.factoryPositionId[0] : null) : item.factoryPositionId,
                  goodsPositionId: Array.isArray(item.goodsPositionId) ? (item.goodsPositionId[0] ? item.goodsPositionId[0] : null) : item.goodsPositionId,
                  distributeRule: item.distributeRule
                }
              }
            )
            hitParams.hitTurnoverRuleDtoList =
              hitParams.hitTurnoverRuleDtoList.map((item) => {
                return {
                  executeSort: item.executeSort,
                  turnoverProp: item.turnoverProp,
                  turnoverRule: item.turnoverRule
                }
              })
          }
          // 限制条件
          const gradeLimitCondition = this.getGradeLimitCondition()
          if (!gradeLimitCondition) return
          hitParams.strategyHitGradeLimitConfigDto = gradeLimitCondition
          this.mode === 'add'
            ? await this.insert(hitParams)
            : this.mode === 'edit'
              ? await this.update(hitParams)
              : await this.copy(hitParams)
        }
      })
    },
    /**
     * 获取等级状态 限制条件 提交参数
     */
    getGradeLimitCondition() {
      // 校验
      if (this.gradeLimitCondition.materialEnableGrade === 1) {
        if (this.gradeLimitCondition.materialGradeSource === 1) {
          if (!this.gradeLimitCondition?.materialGradeStatus?.length) {
            this.$message.error(this.$t('保存失败，请设置等级状态'))
            return false
          }
        }
      }
      if (!this.gradeLimitCondition.materialEnableGrade) {
        this.gradeLimitCondition.materialGradeStatus = null
        this.gradeLimitCondition.materialGradeSource = null
      }
      if (this.gradeLimitCondition.materialGradeSource === 2) {
        this.gradeLimitCondition.materialGradeStatus = null
      }
      return {
        ...this.gradeLimitCondition,
        materialGradeStatus: this.gradeLimitCondition?.materialGradeStatus?.join(',')
      }
    },
    async insert(params) {
      const { code } = await this.$httpService(
        this.$apiStore.strategyManage('addHitStrategy'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.onCancel()
    },
    async update(params) {
      const { code } = await this.$httpService(
        this.$apiStore.strategyManage('updateHitStrategy'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.onCancel()
    },
    async copy(params) {
      const { code } = await this.$httpService(
        this.$apiStore.strategyManage('copyHitStrategy'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.strategy.copySuccessfully'))
      this.onCancel()
    },
    // 取消
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'HitStrategyList',
        mode: ''
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form_title {
  border-left: 4px solid #409eff;
  height: 18px;
  line-height: 20px;
  padding-left: 8px;
  font-size: 16px;
}
.tip {
  display: inline-block;
  position: absolute;
  top: 6px;
  right: -24px;
}
.el-card__body {
  display: flex;
  flex-direction: column;
  .footer-btns {
    flex: 0 0 60px;
    left: 201px;
    bottom: 20px;
    z-index: 10;
    width: calc(100vw - 212px);
    height: 60px;
    line-height: 60px;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
<style lang="scss">
.limit-condition {
  .el-col-2 {
    min-width: 100px;
    padding-right: 0 !important;
    // margin-left: 18px;
  }
}
</style>
